import detailDefault from '../../detailDefault';

/* eslint-disable-next-line camelcase */
export default class detailacm_CampaignsAction extends detailDefault {
    update(sAction, data) {
        if (data.field === 'accounts_acm_samples_1accounts_ida') {
            if (sAction.dataGet(data.prefix + '/fields/name/value')) {
                return;
            }

            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/name/value`, data.value.name);
            sAction.dsAdd('set', `${data.prefix}/changes/name`, data.value.name);
            sAction.dsProcess();
        }
    }
}
